import React, { useState } from 'react'
import { Link, navigate } from "gatsby";
import Layout from '../../../components/layout';
import closeicon from '../../../assets/images/cancel-icon.png';

function ConfirmationFailure() {
  return (
    <Layout setTitle="Pricing">
        <div className="pricing-confirmation-failure">
          <div className="card-section">
            <div className="card-body">
              <img src={closeicon} alt="closeicon" />
            </div>
            <h1>Failure</h1> 
            <p>Oops!<br/>Something went wrong</p>
          </div>
          <div className="text-bottom" >
             To check the payment details, Please 
             <Link to="/user-profile" state={{ orderHistory: "true" }}><span class="button-span">  Click here!</span></Link>
          </div>
        </div>
    </Layout>
  )
}

export default ConfirmationFailure